<template>
  <b-container fluid>
      <b-overlay :show="loading">
         <b-col lg="12" md="12" sm="12" xs="12">
            <div class="text-right">
              <b-button variant="primary" @click="pdfExport" class="btn btn-sm mb-2">
                <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
              </b-button>
            </div>
          </b-col>
        <div class="stepper-wrapper mt-3">
            <div :class="item.value <= detailData.status ? `stepper-item completed` : `stepper-item`"
                  v-for="(item, index) in stepList" :key="index">
                <div class="step-counter">
                    <i v-if="item.value <= detailData.status" class="ri-check-fill"></i>
                    <span v-else>{{ $n(index+1) }}</span>
                </div>
                <div class="step-name">{{ item.text }}</div>
            </div>
        </div>
      <!-- ----------------Primary Section Start------------------- -->
      <template v-if="detailData.trust_type === 1">
        <b-row class="mt-3">
            <b-col lg="6" md="6" sm="6" xs="12">
              <div class="text-justify" style="margin-left:50px">
                <p>{{ $t('teaGardenPduService.tea_garden_name') }} : {{ getPrimaryGardenList(detailData.garden_id) }}</p>
                <p>{{ $t('teaGardenPduService.school_name_garden') }} : {{ getPrimarySchoolList(detailData.garden_id) }}</p>
              </div>
            </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col lg="12" md="12" sm="12" xs="12">
              <div class="text-justify text-center">
               <p>{{ $t('teaGardenPduService.application_form_scholarship') }} - {{ $n(detailData.year, { useGrouping: false })}})
                <p>{{ $t('teaGardenPduService.secound_to_five') }} :</p>
              </div>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col lg="12" md="12" sm="12" xs="12">
              <div class="text-justify">
                <p>{{ $t('teaGardenPduService.text') }} {{ $n(detailData.year, { useGrouping: false }) }} {{ $t('teaGardenPduService.primary_text') }}</p>
              </div>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col lg="12" md="12" sm="12" xs="12">
            <b-table-simple class="tg mt-3" bordered striped hover small caption-top responsive :emptyText="$t('globalTrans.noDataFound')">
              <b-thead>
                <b-tr>
                  <b-th class="text-center">{{ $t('teaGardenPduService.class_name') }}</b-th>
                  <b-th class="text-center">{{ $t('teaGardenPduService.roll_no') }}</b-th>
                  <b-th class="text-center">{{ $t('teaGardenPduService.student_name_status') }}</b-th>
                  <b-th class="text-center">{{ $t('teaGardenPduService.guardian_name_status') }}</b-th>
                  <b-th class="text-center">{{ $t('teaGardenPduService.is_labour_child') }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(info, index) in detailData.primary_details" :key="index">
                  <b-td class="text-center">{{ getPrimaryClassList(info.class_id) }}</b-td>
                  <b-td class="text-center">{{ $n(info.roll_id, { useGrouping: false }) }}</b-td>
                  <b-td class="text-center">{{ currentLocale === 'en' ? info.student_name_en : info.student_name_bn }}</b-td>
                  <b-td class="text-center">{{ currentLocale === 'en' ? info.guardian_name_en : info.guardian_name_bn }}</b-td>
                  <b-td class="text-center">{{ getHasLabourChild(info.has_labour_child )}}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col lg="12" md="12" sm="12" xs="12">
              <div class="text-justify">
                <p>{{ $t('teaGardenPduService.text_one') }}</p>
              </div>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col lg="6" md="6" sm="6" xs="">
              <div class="text-justify">
                <p>{{ $t('teaGardenPduService.head_mobile_number') }} : {{ this.currentLocale === 'en' ? '0' : '০' }}{{ getPrimaryHeadeMobileNumber(detailData.garden_id) }}</p>
              </div>
          </b-col>
          <b-col lg="6" md="6" sm="6" xs="6">
              <div class="text-justify">
                <p>{{ $t('teaGardenPduService.head_teacher_name') }} : {{ getPrimaryHeadTeacherName(detailData.garden_id) }}</p>
              </div>
          </b-col>
        </b-row>
      </template>
      <!-- -----------------Primary Section End------------------- -->
      <!-- ----------------Secoundary Details Section Start------------------- -->
      <template v-if="detailData.trust_type === 2">
        <b-row class="mt-3">
          <b-col lg="12" md="12" sm="12" xs="12">
            <div class="text-justify text-center">
              <p>{{ $t('teaGardenPduService.application_form_scholarship') }} - {{ $n(detailData.year, { useGrouping: false })}})</p>
              <p><u>{{ $t('teaGardenPduService.six_to_eight') }}</u></p>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col lg="12" md="12" sm="12" xs="12">
              <div class="text-justify">
                <p>{{ $t('teaGardenPduService.secoundary_text_two') }}</p>
              </div>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col lg="12" md="12" sm="12" xs="12">
              <div class="text-justify">
                <p>{{ $n(1)}}) {{ $t('teaGardenPduService.applicant_name_student') }} : {{ currentLocale === 'en' ? detailData.applicant_name_en : detailData.applicant_name_bn  }}</p>
              </div>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col lg="12" md="12" sm="12" xs="12">
              <div class="text-justify">
                <p>{{ $n(2)}}) {{ $t('globalTrans.father_name') }} : {{ currentLocale === 'en' ? detailData.father_name_en : detailData.father_name_bn  }}</p>
              </div>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col lg="12" md="12" sm="12" xs="12">
              <div class="text-justify">
                <p>{{ $n(3)}}) {{ $t('globalTrans.mother_name') }} : {{ currentLocale === 'en' ? detailData.mother_name_en : detailData.mother_name_bn  }}</p>
              </div>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col lg="4" md="4" sm="4" xs="12">
              <div class="text-justify">
                <p>{{ $n(4)}}) {{ $t('teaGardenPduService.secoundary_text_three') }}</p>
              </div>
          </b-col>
          <b-col lg="7" md="7" sm="7" xs="12">
              <div class="text-justify" style="margin-left:50px">
                <p>{{ $t('teaGardenPduService.garden_name') }} <u>{{ getGardenList(detailData.garden_id) }}</u></p>
                <!-- <p>{{ $t('teaGardenPduService.name_of_outpost') }} <u></u>---------------------</p> -->
                <p>{{ $t('globalTrans.union') }} <u>{{ getUnionList(detailData.garden_id) }}</u></p>
                <p>{{ $t('globalTrans.upazila') }} <u>{{ getUpazilaList(detailData.garden_id) }}</u></p>
                <p>{{ $t('globalTrans.district') }} <u>{{ getDistrictList(detailData.garden_id) }}</u></p>
                <p>{{ $t('teaGardenPduService.parent_mobile') }} <u>{{ detailData.mobile_no | mobileNumber }}</u></p>
              </div>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col lg="5" md="5" sm="5" xs="12">
              <div class="text-justify">
                <p>{{ $n(5)}}) {{ $n(detailData.year, { useGrouping: false })}} {{ $t('teaGardenPduService.secoundary_text_four') }} : </p>
              </div>
          </b-col>
          <b-col lg="7" md="7" sm="7" xs="12">
              <div class="text-justify">
                <p>{{ getSecoundaryClassList(detailData.secoundary_class_id) }}</p>
              </div>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col lg="4" md="4" sm="4" xs="12">
              <div class="text-justify">
                <p>{{ $n(6)}}) {{ $t('teaGardenPduService.secoundary_text_six') }} :</p>
              </div>
          </b-col>
          <b-col lg="7" md="7" sm="7" xs="12">
              <div class="text-justify" style="margin-left:50px">
                <p>{{ $t('teaGardenPduService.education_institution_name') }} <u>{{ getEducationInstiList(detailData.garden_id) }}</u></p>
                <p>{{ $t('teaGardenPduService.head_teacher_name') }} <u>{{ getTeacherNameList(detailData.garden_id) }}</u></p>
                <p>{{ $t('teaGardenPduService.head_mobile_number') }} <u>{{ this.currentLocale === 'en' ? '0' : '০' }}{{ getMobileNumber(detailData.garden_id) }}</u></p>
                <p>{{ $t('globalTrans.address') }} <u>{{ getAddress(detailData.garden_id) }}</u></p>
                <!-- <p>{{ $t('teaGardenPduService.post_office') }} <u></u>---------------------</p> -->
                <p>{{ $t('globalTrans.upazila') }} <u>{{ getSecoundarySchoolUpazila(detailData.garden_id) }}</u></p>
                <p>{{ $t('globalTrans.district') }} <u>{{ getSecoundarySchoolDistrict(detailData.garden_id) }}</u></p>
              </div>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col lg="12" md="12" sm="12" xs="12">
              <div class="text-justify">
                <p>{{ $n(7)}}) {{ $t('teaGardenPduService.has_previos_scholarship') }} : {{ getScholarshipList(detailData.has_previos_scholarship) }}</p>
              </div>
          </b-col>
        </b-row>
      </template>
      <!-- -----------------Secoundary Section End------------------- -->
      <b-row class="text-right">
        <b-col>
          <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-details')">{{ $t('globalTrans.cancel') }}</b-button>
        </b-col>
      </b-row>
      </b-overlay>
  </b-container>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { eduTrustScholarshipShowPdf } from '../../../api/routes'
export default {
    props: ['items'],
    components: {
    },
    data () {
      return {
        detailData: [],
        users: [],
        loading: false
      }
    },
    created () {
      this.detailData = this.items
    },
    computed: {
      currentLocale () {
        return this.$i18n.locale
      },
      stepList () {
        const list = [
            { value: 2, text: this.$i18n.locale === 'en' ? 'Final Save' : 'চূড়ান্ত সংরক্ষণ', text_en: 'Final Save', text_bn: 'চূড়ান্ত সংরক্ষণ' },
            { value: 3, text: this.$i18n.locale === 'en' ? 'Assigned' : 'বরাদ্দ করা হয়েছে', text_en: 'Assigned', text_bn: 'বরাদ্দ করা হয়েছে' },
            { value: 4, text: this.$i18n.locale === 'en' ? 'Forwarded' : 'ফরোয়ার্ড', text_en: 'Forwarded', text_bn: 'ফরোয়ার্ড' },
            { value: 5, text: this.$i18n.locale === 'en' ? 'Approved' : 'অনুমোদন', text_en: 'Approved', text_bn: 'অনুমোদন' },
            { value: 6, text: this.$i18n.locale === 'en' ? 'Rejected' : 'প্রত্যাখ্যাত', text_en: 'Rejected', text_bn: 'প্রত্যাখ্যাত' }
        ]
        if (this.detailData.status === 6) {
        return list.filter(item => item.value !== 5)
      } else {
        return list.filter(item => item.value !== 6)
      }
      }
    },
    methods: {
      getScholarshipList (status) {
        if (status === 1) {
          return this.currentLocale === 'en' ? 'Yes' : 'হ্যাঁ'
        } else if (status === 2) {
          return this.currentLocale === 'en' ? 'No' : 'না'
        }
      },
      getHasLabourChild (isChild) {
        if (isChild === 1) {
          return this.currentLocale === 'en' ? 'Yes' : 'হ্যাঁ'
        } else if (isChild === 2) {
          return this.currentLocale === 'en' ? 'No' : 'না'
        }
      },
      getGardenList (gardenId) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(e => e.value === gardenId)
        if (obj !== undefined) {
           if (this.$i18n.locale === 'bn') {
            return obj.text_bn
          } else {
            return obj.text_en
          }
        }
      },
      getPrimaryGardenList (gardenId) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(e => e.value === gardenId)
        if (obj !== undefined) {
           if (this.$i18n.locale === 'bn') {
            return obj.text_bn
          } else {
            return obj.text_en
          }
        }
      },
      getDistrictList (gardenId) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(e => e.value === gardenId)
        const districtObj = this.$store.state.CommonService.commonObj.districtList.find(el => el.value === obj.district_id)
        if (obj !== undefined) {
           if (this.$i18n.locale === 'bn') {
            return districtObj.text_bn
          } else {
            return districtObj.text_en
          }
        }
      },
      getUpazilaList (gardenId) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(e => e.value === gardenId)
        const upazilaObj = this.$store.state.CommonService.commonObj.upazilaList.find(el => el.value === obj.upazila_id)
        if (obj !== undefined) {
           if (this.$i18n.locale === 'bn') {
            return upazilaObj.text_bn
          } else {
            return upazilaObj.text_en
          }
        }
      },
      getUnionList (gardenId) {
        const obj = this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.find(e => e.value === gardenId)
        const unionObj = this.$store.state.CommonService.commonObj.unionList.find(el => el.value === obj.union_id)
        if (obj !== undefined) {
           if (this.$i18n.locale === 'bn') {
            return unionObj?.text_bn
          } else {
            return unionObj?.text_en
          }
        }
      },
      getEducationInstiList (gardenId) {
        const schoolObj = this.$store.state.TeaGardenService.commonObj.masterSchoolInformationList.find(el => el.garden_id === gardenId && JSON.parse(el.school_type).includes(this.detailData.trust_type))
        if (schoolObj !== undefined) {
           if (this.$i18n.locale === 'bn') {
            return schoolObj.text_bn
          } else {
            return schoolObj.text_en
          }
        }
      },
      getPrimarySchoolList (gardenId) {
        const schoolObj = this.$store.state.TeaGardenService.commonObj.masterSchoolInformationList.find(el => el.garden_id === gardenId && JSON.parse(el.school_type).includes(this.detailData.trust_type))
        if (schoolObj !== undefined) {
           if (this.$i18n.locale === 'bn') {
            return schoolObj.text_bn
          } else {
            return schoolObj.text_en
          }
        }
      },
      getTeacherNameList (gardenId) {
        const schoolObj = this.$store.state.TeaGardenService.commonObj.masterSchoolInformationList.find(el => el.garden_id === gardenId && JSON.parse(el.school_type).includes(this.detailData.trust_type))
        if (schoolObj !== undefined) {
           if (this.$i18n.locale === 'bn') {
            return schoolObj.h_master_name_bn
          } else {
            return schoolObj.h_master_name_en
          }
        }
      },
      getPrimaryHeadTeacherName (gardenId) {
        const schoolObj = this.$store.state.TeaGardenService.commonObj.masterSchoolInformationList.find(el => el.garden_id === gardenId && JSON.parse(el.school_type).includes(this.detailData.trust_type))
        if (schoolObj !== undefined) {
           if (this.$i18n.locale === 'bn') {
            return schoolObj.h_master_name_bn
          } else {
            return schoolObj.h_master_name_en
          }
        }
      },
      getMobileNumber (gardenId) {
        const schoolObj = this.$store.state.TeaGardenService.commonObj.masterSchoolInformationList.find(el => el.garden_id === gardenId && JSON.parse(el.school_type).includes(this.detailData.trust_type))
        if (schoolObj !== undefined) {
          return this.$n(schoolObj.h_master_mobile, { useGrouping: false })
        }
      },
      getPrimaryHeadeMobileNumber (gardenId) {
        const schoolObj = this.$store.state.TeaGardenService.commonObj.masterSchoolInformationList.find(el => el.garden_id === gardenId && JSON.parse(el.school_type).includes(this.detailData.trust_type))
        if (schoolObj !== undefined) {
          return this.$n(schoolObj.h_master_mobile, { useGrouping: false })
        }
      },
      getAddress (gardenId) {
        const schoolObj = this.$store.state.TeaGardenService.commonObj.masterSchoolInformationList.find(el => el.garden_id === gardenId && JSON.parse(el.school_type).includes(this.detailData.trust_type))
        if (schoolObj !== undefined) {
           if (this.$i18n.locale === 'bn') {
            return schoolObj.address_bn
          } else {
            return schoolObj.address_en
          }
        }
      },
      getSecoundarySchoolUpazila (gardenId) {
        const schoolObj = this.$store.state.TeaGardenService.commonObj.masterSchoolInformationList.find(el => el.garden_id === gardenId && JSON.parse(el.school_type).includes(this.detailData.trust_type))
        const upazilaObj = this.$store.state.CommonService.commonObj.upazilaList.find(el => el.value === schoolObj.upazila_id)
        if (upazilaObj !== undefined) {
          if (this.$i18n.locale === 'bn') {
            return upazilaObj.text_bn
          } else {
            return upazilaObj.text_en
          }
        }
      },
      getSecoundarySchoolDistrict (gardenId) {
        const schoolObj = this.$store.state.TeaGardenService.commonObj.masterSchoolInformationList.find(el => el.garden_id === gardenId && JSON.parse(el.school_type).includes(this.detailData.trust_type))
        const districtObj = this.$store.state.CommonService.commonObj.districtList.find(el => el.value === schoolObj.district_id)
        if (schoolObj !== undefined) {
           if (this.$i18n.locale === 'bn') {
            return districtObj.text_bn
          } else {
            return districtObj.text_en
          }
        }
      },
      getSecoundaryClassList (classId) {
        if (classId === 1) {
          return this.currentLocale === 'en' ? 'Six' : '৬ষ্ঠ'
        } else if (classId === 2) {
          return this.currentLocale === 'en' ? 'Seven' : '৭ম'
        } else if (classId === 3) {
          return this.currentLocale === 'en' ? 'Eight' : '৮ম'
        }
      },
      getPrimaryClassList (classId) {
        if (classId === 1) {
          return this.currentLocale === 'en' ? 'Two' : '২য়'
        } else if (classId === 2) {
          return this.currentLocale === 'en' ? 'Three' : '৩য়'
        } else if (classId === 3) {
          return this.currentLocale === 'en' ? 'Four' : '৪র্থ'
        } else if (classId === 4) {
          return this.currentLocale === 'en' ? 'Five' : '৫ম'
        }
      },
      async pdfExport () {
        this.loading = true
        const trustType = this.items.trust_type
        const appId = this.items.id
        const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, trust_type: trustType, app_id: appId })
        const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 29)
        if (service !== undefined) {
          if (service.office_type_id) {
            params.office_type_id = service.office_type_id
          }
          if (service.office_id) {
            params.office_id = service.office_id
          }
        }
        const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, eduTrustScholarshipShowPdf + '/' + appId, params)
        var blob = new Blob([result], {
          type: 'application/pdf'
        })
        var url = window.URL.createObjectURL(blob)
        window.open(url).print()
        this.loading = false
      }
    }
}

</script>
<style scoped>
    .tag {
        font-weight: 600;
        border-left: 4px solid #1c4261;
        padding-left: 6px;
    }
    .download-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 2px 4px;
    }
    .yes-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
    }
    .no-btn {
        color: red;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
        margin-right: 33px !important;
    }
    .tag {
        font-weight: 600;
        border-left: 4px solid #1c4261;
        padding-left: 6px;
    }
    .download-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 2px 4px;
    }
    .yes-btn {
        color: green;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
    }
    .no-btn {
        color: red;
        border: 1px solid gray;
        margin-left: 5px;
        font-size: 18px;
        padding: 1px 3px;
        margin-right: 33px !important;
    }

    .stepper-wrapper {
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .stepper-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
    }

    @media (max-width: 768px) {
        font-size: 12px;
    }
    .stepper-item::before {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 20px;
        left: -50%;
        z-index: 2;
    }

    .stepper-item::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #ccc;
        width: 100%;
        top: 20px;
        left: 50%;
        z-index: 2;
    }

    .stepper-item .step-counter {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #ccc;
        margin-bottom: 6px;
        color: white;
    }

    .stepper-item.active {
        font-weight: bold;
    }

    .stepper-item.completed .step-counter {
        background-color: #4bb543;
        color:white;
    }

    .stepper-item.completed::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #4bb543;
        width: 100%;
        top: 20px;
        left: 50%;
        z-index: 3;
    }

    .stepper-item:first-child::before {
        content: none;
    }
    .stepper-item:last-child::after {
        content: none;
    }
    .step-name {
        color: black;
        font-weight: bold;
        font-size: 11px;
    }
    .tagTwo {
        position: relative;
        display: inline-block;
        border-radius: 6px;
        clip-path: polygon(20px 0px, 100% 0px, 100% 100%, 0% 100%, 0% 20px);
        background: hsl(250deg, 100%, 40%);
        padding: 5px 25px;
        font-weight: 600;
        font-size: 12px;
        color: #FFF;
        transition: clip-path 500ms;
    }
    .tagTwo:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background: hsl(252deg, 100%, 60%);
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
        border-radius: 0 0 6px 0;
        transition: transform 500ms;
    }
</style>
