// pdu course configuration routes
const course = '/pdu/configuration/course/'
export const courseList = course + 'list'
export const courseStore = course + 'store'
export const courseUpdate = course + 'update'
export const courseToggle = course + 'toggle-status'
// pdu circular routes
const circular = '/pdu/circular-management/circular/'
export const circularList = circular + 'list'
export const circularStore = circular + 'store'
export const circularUpdate = circular + 'update'
export const circularShow = circular + 'show'
export const circularToggle = circular + 'toggle-status'
export const getCircularBatchNumber = circular + 'get-batch-number'
export const circularForward = circular + 'forward'
export const circularApprove = circular + 'approve'
export const circularRequestList = circular + 'forwarded-list'
export const circularApprovedList = circular + 'approved-list'
export const circularClosed = circular + 'closed'
// pdu course management routes
const courseManagement = '/pdu/course-management/'
// applicant info routes
const applicantInfo = courseManagement + 'applicant-info/'
export const applicantInfoListApi = applicantInfo + 'list'
export const applicantInfoStoreApi = applicantInfo + 'store'
export const applicantInfoUpdateApi = applicantInfo + 'update'
export const applicantInfoShowApi = applicantInfo + 'show'
export const applicationDetails = applicantInfo + 'show'
export const applicantInfoVerifyApi = applicantInfo + 'verify'
export const verificationListApi = applicantInfo + 'verification-list'
export const confirmedListApi = applicantInfo + 'confirmed-list'
export const applicantInfoCertifyApi = applicantInfo + 'certify'
export const certifiedListApi = applicantInfo + 'certified-list'

// other info routes
const otherInfo = courseManagement + 'other-info/'
export const otherInfoStoreApi = otherInfo + 'store'
export const otherInfoUpdateApi = otherInfo + 'update'
// application report
export const participantReportList = courseManagement + 'report/application-participant'

// payment routes
const paymentInfo = courseManagement + 'circular-payment/'
export const applicationPaymentOffline = paymentInfo + 'store-offline-payment'
export const applicationPaymentOnline = paymentInfo + 'store-online-payment'
export const paymentSuccess = paymentInfo + 'success'
export const PaymentCancel = paymentInfo + 'cancel'
export const PaymentDecline = paymentInfo + 'decline'

// education trust scholarship Router
const eduTrustScholarship = '/pdu/education-trust-scholarship/application/'
export const eduTrustScholarshipStore = eduTrustScholarship + 'store'
export const eduTrustScholarshipList = eduTrustScholarship + 'list'
export const eduTrustScholarshipShow = eduTrustScholarship + 'show'
export const eduTrustScholarshipUpdate = eduTrustScholarship + 'update'
export const eduTrustScholarshipAssign = eduTrustScholarship + 'assign'
export const eduTrustScholarshipShowPdf = eduTrustScholarship + 'show-pdf'

// education trust scholarship Received List
const eduTrustReceive = '/pdu/education-trust-scholarship/received/'
export const eduTrustReceivedList = eduTrustReceive + 'list'
export const eduTrustReceivedForward = eduTrustReceive + 'forward'

// education trust scholarship Forward List
const eduTrustForward = '/pdu/education-trust-scholarship/forward/'
export const eduTrustForwardList = eduTrustForward + 'list'
export const eduTrustForwardApprove = eduTrustForward + 'approve'
export const eduTrustForwardReject = eduTrustForward + 'reject'

// education trust scholarship Forward List
const eduTrustApprove = '/pdu/education-trust-scholarship/approve/'
export const eduTrustApproveList = eduTrustApprove + 'list'

// education trust scholarship Forward List
const eduTrustReject = '/pdu/education-trust-scholarship/reject/'
export const eduTrustRejectList = eduTrustReject + 'list'
//  Regular Garden Inspection
const regularGarIns = '/pdu/regular-garden-inspection/application/'
export const regularGarInslList = regularGarIns + 'list'
export const regularGarInsStore = regularGarIns + 'store'
export const regularGarInsUpdate = regularGarIns + 'update'
export const regularGarInsPublish = regularGarIns + 'schedule-publish'
export const regularGarInsDetails = regularGarIns + 'show'
export const regularGarInsAssigne = regularGarIns + 'assign'
// Prepare M & E REPORT Routes..
const prepareMEreport = '/pdu/regular-garden-inspection/prepare-me-report/'
export const prepareMEreportList = prepareMEreport + 'list'
export const prepareMEgardenInfoList = prepareMEreport + 'garden-info-list'
export const masterGardenProfileList = prepareMEreport + 'garden-profile-list'
export const prepareMEGardenReportUpdate = prepareMEreport + 'garden-report-update'
export const regularGarInsApprove = prepareMEreport + 'approve'
export const regularGarInsApproveGardenList = prepareMEreport + 'approve-garden-list'
// Garden Evaluation and Insection related routes
const evaluationReport = '/pdu/regular-garden-inspection/evaluation-report/'
export const evaluationReportAppInfo = evaluationReport + 'application-info'
export const evaluationReportTab1Submit = evaluationReport + 'submit-general-info'
// stats tab
export const statsPartOneStoreUpdate = evaluationReport + 'stats-part-one-store-update'
export const statsPartTwoStoreUpdate = evaluationReport + 'stats-part-two-store-update'
export const statsPartThreeStoreUpdate = evaluationReport + 'stats-part-three-store-update'
export const statsPartFourStoreUpdate = evaluationReport + 'stats-part-four-store-update'
export const statsPartFiveStoreUpdate = evaluationReport + 'stats-part-five-store-update'
// tea planting info tab
export const teaPlantingStoreUpdate = evaluationReport + 'tea-planting-store-update'

// tea development activities tab
export const developmentActivityStoreUpdate = evaluationReport + 'development-activities-store-update'

// tea productions tab
export const teaProductionsStoreUpdate = evaluationReport + 'tea-productions-store-update'

// tea management info tab
export const managementInfoStoreUpdate = evaluationReport + 'management-info-store-update'

// export const statTabTwoUpdate = evaluationReport + 'update-stat-tab-two'
// Garden Insepction Routes..
const gardenInspectionReport = '/pdu/regular-garden-inspection/garden-insepction-report/'
export const pduGardenInspectionApi = gardenInspectionReport + 'list'

// student scholarship Routes..
const studentScholarshipReport = '/pdu/education-trust-scholarship/student-scholarship-report/'
export const studentScholarshipApi = studentScholarshipReport + 'list'

// master machine name routes......
const machineNameBase = '/pdu/configuration/machine-name/'
export const machineNameList = machineNameBase + 'list'
export const machineNameStore = machineNameBase + 'store'
export const machineNameUpdate = machineNameBase + 'update'
export const machineNameToggle = machineNameBase + 'toggle-status'
